.countdown-cont {
  display: flex;
  flex-direction: column;
  gap: 20px;
  padding: 40px 0 30px;
  align-items: center;
}
.countdown-cont h2 {
  color: #fff;
  font-family: Inter;
  font-size: 30px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
}
.countdown-cont p{
    color: #FFF;
    font-family: Inter;
    font-size: 70px;
    font-style: normal;
    font-weight: 800;
    line-height: normal;
    text-transform: capitalize;
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 5px;
    border-right: 2px solid white;
    padding: 0 30px;
}
.countdown-cont p:last-child{
    border-right: none;
}
.countdown-cont p span{
    color: #FFF;
    text-align: center;
    font-family: Inter;
    font-size: 20px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
    text-transform: capitalize;
}
.count-timer{
    display: flex;
    /* gap: 40px; */
    align-items: center;
}

@media (max-width: 700px) {
    .countdown-cont h2{
        font-size: 18px;
    }
    .countdown-cont p{
        font-size: 20px;
        padding: 0 20px;
    }
    .countdown-cont p span{
        font-size: 14px;
    }
    .countdown-cont{
        width: 95%;
        margin: 0 auto;
        padding-top: 55px;
    }
}