*{
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}

body{
  max-width: 2000px;
  margin: 0 auto;
  font-family: "Inter", sans-serif;
  background: black;
}
button, input{
  outline: none;
  background: transparent;
  border: none;
}
html {
  scroll-behavior: smooth;
}


.btn-trans{
  display: inline-flex;
  padding: 16px 50px;
  justify-content: center;
  align-items: center;
  gap: 10px;
  border-radius: 10px;
  border: 3px solid var(--style, #F7931E);
  cursor: pointer;
  color: #FFF;
  font-family: Inter;
  font-size: 30px;
  font-style: normal;
  font-weight: 300;
  line-height: normal;
  text-transform: capitalize;
}
.btn{
  border-radius: 10px;
  background: var(--style, linear-gradient(259deg, #F7931E -4.22%, #F15A24 37.12%, #9E005D 94%));
  display: flex;
  padding: 16px 50px;
  justify-content: center;
  align-items: center;
  gap: 10px;
  cursor: pointer;
  color: #FFF;
  font-family: Inter;
  font-size: 30px;
  font-style: normal;
  font-weight: 300;
  line-height: normal;
  text-transform: capitalize;
  width: 100%;
}
.btn-inline{
  border-radius: 10px;
  background: var(--style, linear-gradient(259deg, #F7931E -4.22%, #F15A24 37.12%, #9E005D 94%));
  display: inline-flex;
  padding: 16px 50px;
  justify-content: center;
  align-items: center;
  gap: 10px;
  cursor: pointer;
  color: #FFF;
  font-family: Inter;
  font-size: 14px;
  font-style: normal;
  font-weight: 300;
  line-height: normal;
  text-transform: capitalize;
}
.btn img, .btn-trans img{
  width: 20px;
  height: 20px;
}
.opacity{
  opacity: 0.5;
  cursor: not-allowed;
}

@media (max-width: 700px) {
  .btn-trans{
    padding: 14px 25px;
    font-size: 20px;
  }
  .btn{
    padding: 14px 25px;
    font-size: 20px;
  }
}
