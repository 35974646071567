
.nav-cont{
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 85%;
    margin: 0 auto;
    padding: 40px 0;
}
.nav-left{
    display: flex;
    gap: 12px;
    align-items: center;
}
.nav-left img{
    width: 250px;
    height: 36px;
}
.nav-left h3{
    color: #FFF;
    font-family: Inter;
    font-size: 40px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
}
.nav-right ul{
    display: flex;
    align-items: center;
    list-style: none;
    gap: 30px;
}
.nav-right ul li{
    color: #FFF;
    font-family: Inter;
    font-size: 30px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
    /* text-transform: capitalize; */
}
.active-style{
    color: #F15A24 !important;
    font-weight: 700 !important;
}
.lang-select{
    position: fixed;
    right: 20px;
    top: 10px;
    padding: 3px 10px;
    border-radius: 10px;
    border: 1px solid rgba(255, 255, 255, 0.10);
    background: rgba(50, 48, 48, 0.6);
    color: rgba(255, 255, 255, 0.70);
    font-family: Inter;
    font-size: 18px;
    font-style: normal;
    font-weight: 300;
    line-height: normal;
    cursor: pointer;
    z-index: 2;
}
.lang-select option{
    color: black;
}

@media (max-width: 700px) {
    .nav-cont{
        width: 90%;
    }
    .nav-left{
        gap: 10px;
    }
    .nav-right ul{
        gap: 20px;
    }
    .nav-left img{
        width: 150px;
        height: 24px;
    }
    .nav-cont h3{
        font-size: 26px;
    }
    .nav-right ul li{
        font-size: 20px;
    }
}