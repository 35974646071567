.signup-cont{
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 30px;
    padding: 70px 0;
    background: #000;
}
.signup-cont h2{
    color: #F15A24;
    text-align: center;
    font-family: Inter;
    font-size: 50px;
    font-style: normal;
    font-weight: 800;
    line-height: normal;
    text-transform: capitalize;
}
.signup-cont p{
    color: #FFF;
    text-align: center;
    font-family: Inter;
    font-size: 30px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
    width: 60%;
    margin: 0 auto;
}
.signup-form{
    width: 50%;
    margin: 0 auto;
    display: flex;
    flex-direction: column;
    gap: 30px;
    padding: 40px 0;
}
.signup-btn{
    padding-top: 20px;
}

/* international phone style  */
.inter-phone{
    /* border-radius: 10px !important; */
    border: 1px solid rgba(255, 255, 255, 0.10) !important;
    background: rgba(255, 255, 255, 0.10) !important;
    width: 100%;
    height: 70px !important;
    color: rgba(255, 255, 255, 0.70) !important;
    font-family: Inter !important;
    font-size: 30px !important;
    font-style: normal !important;
    font-weight: 300 !important;
    line-height: normal !important;
    /* text-transform: capitalize; */
    padding: 20px 30px;
    border-top-right-radius: 10px !important;
    border-bottom-right-radius: 10px !important;
}
.inter-phone_div{
    display: flex;
    flex-direction: column;
    gap: 5px;
}
.react-international-phone-country-selector-button{
    border: 1px solid rgba(255, 255, 255, 0.10) !important;
    background: rgba(255, 255, 255, 0.10) !important;
    height: 70px !important;
    padding: 0 5px !important;
    border-top-left-radius: 10px !important;
    border-bottom-left-radius: 10px !important;
}
.inter-phone_div h4{
    color: rgba(255, 255, 255, 0.90);
    font-family: Inter;
    font-size: 30px;
    font-style: normal;
    font-weight: 600;
    line-height: normal;
    text-transform: capitalize;
    text-align: left !important;
}


@media (max-width: 700px) {
    .signup-cont h2{
        font-size: 36px;
    }
    .signup-cont p{
        font-size: 20px;
        width: 85%;
    }
    .signup-form{
        width: 80%;
    }
    .inter-phone_div h4{
        font-size: 16px !important;
    }
    .inter-phone{
        font-size: 16px !important;
    }
    .react-international-phone-country-selector-button{
        height: 55px !important;
    }
    .inter-phone{
        height: 55px !important;
    }
    
}