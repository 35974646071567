
.form-input{
    display: flex;
    flex-direction: column;
    gap: 6px;
    justify-content: left;
}
.form-input label{
    color: rgba(255, 255, 255, 0.90);
    font-family: Inter;
    font-size: 30px;
    font-style: normal;
    font-weight: 600;
    line-height: normal;
    text-transform: capitalize;
}
.form-input input{
    border-radius: 10px;
    border: 1px solid rgba(255, 255, 255, 0.10);
    background: rgba(255, 255, 255, 0.10);
    width: 100%;
    color: rgba(255, 255, 255, 0.70);
    font-family: Inter;
    font-size: 30px;
    font-style: normal;
    font-weight: 300;
    line-height: normal;
    /* text-transform: capitalize; */
    padding: 20px 30px;
}
.success-alert{
    border-radius: 10px;
    border: 1px solid rgba(255, 255, 255, 0.10);
    background: rgba(255, 255, 255, 0.10);
    backdrop-filter: blur(15px);
    display: flex;
    /* width: 35vw; */
    padding: 20px;
    align-items: center;
    gap: 20px;
    position: fixed;
    right: 0;
    top: 10vh;
}
.success-alert p{
    color: #FFF;
    font-family: Inter;
    font-size: 25px;
    font-style: normal;
    font-weight: 800;
    line-height: normal;
    /* border: 1px solid red; */
    width: 100%;
}
.success-alert img{
    width: 34px;
    height: 34px;
}
.read-more h3 {
    color: #fff;
    font-family: "Albert Sans";
    font-size: 16px;
    font-style: normal;
    font-weight: 400;
    line-height: 30px; /* 150% */
    /* text-transform: capitalize; */
  }

@media (max-width: 700px) {
    .form-input label{
        font-size: 16px;
    }
    .form-input input{
        font-size: 16px;
        padding: 16px 23px;
    }
    .success-alert{
        width: 95vw;
        top: 20px;
    }
    .success-alert p{
        font-size: 14px;
    }
    .read-more h3{
        font-size: 14px;
    }
}